.link-section-container {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .link-section-main-title {
        text-align: center;
        font-family: $font_family;
        font-size: 1.2rem;
        color: $sugbil_dark_grey;
        margin: 3rem 0;
    }

    .link-section-column {
        .image-link {
            position: relative;
            display: block;
        }

        .image-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 1rem;
            color: $sugbil_white;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .link-four-section-column {
        padding: 0;

        .image-title {
            font-size: 1rem;
        }
    }
}