.image-section {
  margin: 4.7em 0;

  .image-section-container {
    .image-section-column {
      text-align: center;

      .image-section-title {
        text-transform: uppercase;
        margin-bottom: 3.125rem;
        font-family: $font_family;
      }
    }

    .img-section-image {
      max-height: 16.5rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .image-section {
    .image-section-container {
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;

      .img-section-image {
        max-width: 100%;
      }
    }
  }
}

.page-text {
  font-size: 1.125rem;
  font-family: $font_family;
  font-weight: 500;
  line-height: 1.875rem;
  padding-bottom: 1rem;
  text-align: left;
}
