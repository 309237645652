.main-section-container {
  text-align: center;

  .main-section-row {
    .main-section-image-text {
      padding-bottom: 1rem;

      .main-section-image {
        max-width: 100%;
      }

      .main-section-text {
        font-size: 1.125rem;
        font-family: $font_family;
        font-weight: 500;
        line-height: 1.875rem;
      }
    }
  }

  .main-section-row {
    justify-content: center;

    .main-column-box {
      text-decoration: none;
      cursor: pointer;
    }

    .main-column-1 {
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0 0.625rem 0.625rem 0;
      max-width: 48%;
      padding: 1.75rem 0;
    }
    .main-column-2 {
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0 0 0.625rem 0.625rem;
      max-width: 48%;
      padding: 1.75rem 0;
    }
    .main-column-3 {
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0.625rem 0.625rem 0 0;
      max-width: 48%;
      padding: 1.75rem 0;
    }
    .main-column-4 {
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0.625rem 0 0 0.625rem;
      max-width: 48%;
      padding: 1.75rem 0;
    }
  }

  .main-section-title {
    font-size: 2rem;
    font-family: $font_family;
    font-weight: 500;
    color: $main_green;
    text-align: center;
  }

  .title-icon {
    width: 2rem;
  }

  .sub-page-title {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: $sugbil_dark_grey;
    text-align: center;
  }

  .main-section-description {
    font-size: 1.125rem;
    font-family: $font_family;
    font-weight: 500;
    line-height: 1.875rem;
    padding-bottom: 1rem;
    text-align: left;
  }

  .additional-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transform: translateY(0.625rem);
    transition: opacity 1s ease, transform 1s ease, max-height 1s ease;
  }

  .fade-in {
    opacity: 1;
    max-height: 65rem;
    transform: translateY(0);
  }

  .fade-out {
    opacity: 0;
    max-height: 0;
    transform: translateY(0.625rem);
  }

  .sub-page-description {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.875rem;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
    text-align: left;
  }

  .main-section-address {
    font-size: 1rem;
    padding-bottom: 1rem;
    margin: 3.75rem 0;
  }

  .info-title {
    text-transform: uppercase;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .info-box {
    padding: 3.125rem 0;
  }

  .info-contact-title a {
    color: #000;
    font-size: 1rem;
    text-decoration: none;
    line-height: 1;
  }

  .main-section-separator {
    height: 1px;
    width: 100%;
    background-color: $separator_grey;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .main-column-description {
    color: $sugbil_text;
    font-family: $font_family;
    font-weight: 300;
    padding: 0;
    font-size: 1rem;
    margin-bottom: 1.875rem;
  }

  .row-contact {
    max-width: 50rem;
    margin: 0 auto;
  }

  .info-contact {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0;
    margin: 0 1.5rem;

    .info-contact-icon {
      width: 1.125rem;
    }
  }

  .address-column {
    border: 1px solid rgba(0, 0, 0, 0.3);
    max-width: 585px;
    margin: 3.125rem auto 0;
    padding: 0 3.125rem;
  }
}

@media screen and (max-width: 991px) {
  .main-section-container {
    .main-section-image-text {
      flex-direction: column;

      .main-section-column {
        max-width: 100%;

        .main-section-image {
          padding-bottom: 3.125rem;
        }

        .main-section-text {
          font-size: 1rem;
        }
      }
    }

    .address-column {
      max-width: 510px;
    }
  }
}

@media screen and (max-width: 500px) {
  .main-section-container {
    .row-contact {
      margin: 0;

      .info-contact-title a {
        font-size: 1.2rem;
      }

      .main-section-address {
        font-size: 1.2rem;
      }

      .address-column {
        margin: 1.5rem auto 0;
      }

      .info-box {
        height: 200px;
      }

      .info-box-2 {
        padding: 0;
        height: 200px;

        .info-contact-icon {
          padding-top: 2.25rem;
        }
      }

      .info-contact {
        margin: 1.25rem auto;
      }
    }

    .main-section-title {
      font-size: 2rem;
    }

    .main-section-description {
      font-size: 1rem;
    }

    .main-section-row {
      .main-column-1,
      .main-column-2,
      .main-column-3,
      .main-column-4 {
        max-width: 90%;
        padding: 0;
        margin: 1.875rem;

        .info {
          padding: 1.875rem 0.625rem;
        }
      }
    }
  }
}
