// Colors
$sugbil_white: #fff;
$sugbil_black: #000;
$sugbil_text: #212529;
$main_green: #53C226;
$sugbil_grey: #d8d8d8;
$social_media: #888888;
$facebook_blue: #3B5998;
$instagram_blue: #125688;
$separator_grey: #9a9a9a;
$sugbil_dark_grey: #2C2C2C;

// Breakpoints
$breakpoint-max-width: 991px;

// Font Family
$font_family: 'Montserrat', sans-serif;

