.sugbil-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 99;

    .sugbil-btn {
        background-color: $main_green;
        border: none;
        padding: 0.625rem 1.25rem;
        margin: 0 auto;
        text-align: center;
        color: $sugbil_white;
        border-radius: 19px;
        font-family: $font_family;
        font-size: 0.875rem;
        transition: all .4s ease-in-out;

        &:hover {
            background-image: linear-gradient(to top, #9dd492 0%, #53C226 100%);
            background-color: #53C226;
            transition: all .4s ease-in-out;
        }
        

        &:focus {
            outline: none;
        }
    }
}


