.contact-form {
  max-width: 50%;
  margin: 3rem auto;

  h2 {
    text-align: center;
    font-family: $font_family;
    font-size: 1.5rem;
  }

  label {
    font-size: 16px;
    font-family: $font_family;
  }

  .contact-form-input {
    font-size: 16px;
    border-radius: 0;
    font-family: $font_family;
  }

  textarea {
    border: 1px solid #e3e3e3;
  }

  textarea:focus {
    border: 1px solid rgb(244, 128, 90);
  }

  .contact-form-button {
    background-color: $main_green;
    font-family: $font_family;
  }

  .contact-form-error {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .contact-form {
    max-width: 100%;
    margin: 2rem;
  }
}
