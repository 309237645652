.footer {
    max-height: 5rem;
    position: relative;
    
    .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;

        ul {
            li {
                a {
                    font-size: 0.875rem;
                    margin-right: 1.875rem;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    text-transform: uppercase;
                }

                .fa-facebook-square {
                    color: $facebook_blue;
                    font-size: 1.125rem;
                }

                .fa-instagram {
                    color: $instagram_blue;
                    font-size: 1.125rem;
                }
            }

            .social-icon-li {
                background-color: $sugbil_white;
                position: relative;
                margin-right: 1.5rem;
                margin-bottom: -1rem;
                padding: 1px;
                border: 1px solid;
                border-radius: 100%;
                height: 2.5rem;
                width: 2.5rem;

                a {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }

        p {
            margin: 0;
            font-size: 0.875rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
        }
    }
}

@media screen and (max-width: 991px) {
    .footer-container {
        max-width: 100%;

        ul {
            &:not(.links-vertical){
                margin-right: 1.875rem;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .footer {
        max-height: 100%;

        .footer-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            ul {
                &:not(.links-vertical){
                    margin: 5px auto;
                }

                li {
                    a {
                        font-size: 1rem;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            p {
                font-size: 1rem;
                margin-top: 0.625rem;
            }
        }
    }
}