.hero-img {
  height: 85vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.hero-content {
  margin-top: -10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: #fff;
  font-family: $font_family;
  padding: 1rem;
  max-width: 600px;
  h1 {
    font-size: 3rem;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0 0 0.5rem;
    text-align: center;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0;
    font-weight: 600;
    text-align: center;
  }
  p {
    font-size: 1.125rem;
    margin: 2.5rem 0;
    line-height: 1.375rem;
    font-weight: 500;
    text-align: center;
  }
  .hero-button-wrapper button {
    margin-top: 2.5rem auto;
    min-width: 13.75rem;
    padding: 0.7rem 4.375rem;
    font-size: 0.75rem;
    font-weight: 500;
    background-color: #53c226;
    color: #fff;
    border: none;
    border-radius: 19px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  button:hover {
    background-color: #c03d19;
  }
}

@media screen and (max-width: 991px) {
  .hero-img {
    height: 90vh;
  }
}

@media screen and (max-width: 670px) {
  .hero-content {
    margin-top: -8rem;
  }
}

@media (max-width: 36rem) {
  .hero-content {
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
      margin: 1rem 0;
      line-height: 1.2;
    }
    .hero-button-wrapper {
      margin-top: 1rem;
      margin-left: -1.5rem;
    }
  }
}
