.navbar {
  min-height: 4.375rem;
  position: relative;
  z-index: 2;
  background-color: $sugbil_white;

  .nav-container {
    max-width: 1140px;
    position: relative;

    .nav-logo {
      max-width: 8.75rem;
      border-radius: 1px;
      position: absolute;
      top: 10px;
    }
  }

  .navbar-translate {
    width: 140px;
  }

  .navbar-collapse {
    justify-content: center;
  }

  .nav-items {
    line-height: 1.8em;
    font-size: 0.875rem;
    font-weight: 400;
    color: $sugbil_text;
    padding: 10px 15px;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: none;
  }

  &.navbar-absolute {
    position: absolute;
    width: 100%;
    padding-top: 10px;
    z-index: 1029;
  }

  .navbar-toggler {
    vertical-align: middle;

    &.navbar-toggler-left {
      top: 0;
    }

    &.navbar-toggler-right {
      padding-right: 0;
      top: 8px;
    }
  }
  .active-link {
    background-color: $sugbil_grey;
  }

  .dropdown-menu.dropdown-menu-right::before {
    display: none;
  }

  .contact-icons {
    display: flex;
    align-items: center;
    gap: 1rem;

    .phone-icon,
    .email-icon {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .phone-number,
    .email-text {
      font-size: 0.875rem;
      font-weight: 400;
      color: $sugbil_text;
      &:hover {
        text-decoration: underline;
      }
    }

    .phone-icon img,
    .email-icon img {
      width: 20px !important;
      height: auto;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 1120px) {
  .nav-items,
  .phone-number,
  .email-text {
    font-size: 0.75rem !important;
  }
}

@media screen and (max-width: 1050px) {
  .nav-items,
  .phone-number,
  .email-text {
    font-size: 0.65rem !important;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    min-height: 3.125rem;

    .nav-container {
      .navbar-nav {
        margin-left: 0;
      }

      .nav-logo {
        max-width: 6.5rem;
        top: 5px;
      }

      .phone-icon {
        display: none;
      }

      .nav-items {
        color: $sugbil_white;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
