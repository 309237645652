.bg-wrapper {
    position: relative;

    img {
        position: fixed;
        top: 0;
        right: 0;
        max-width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .bg-wrapper {
        img {
            top: 50px;
            right: -50%;
            max-width: 200%;
        }
    }
}